<template>
  <b-overlay class="row" :show="loading">
    <div class="col-12 c g">
      <br /><br />
      <div class="card card-body" v-if="calendar">
        <div class="col-12 table-responsive" v-if="type == 'class'">
          <div class="col-12 text-center">
            <h2 class="g">
              الجدول المدرسي الخاص بالفصل <u>{{ classroomName(id) }}</u>
            </h2>
          </div>
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>اليوم</th>
              <th>الأولى</th>
              <th>الثانية</th>
              <th>الثالثة</th>
              <th>الرابعة</th>
              <th>الخامسة</th>
              <th>السادسة</th>
              <th>السابعة</th>
              <th>الثامنة</th>
            </thead>
            <tbody>
              <tr v-for="day in days" :key="day.code">
                <td>
                  {{ day.name }}
                </td>
                <td
                  v-for="index in 8"
                  :key="index"
                  class="drag"
                  :fullid="day.code + '|' + classroom.id + '|' + index"
                >
                  <span
                    v-if="calendar[day.code + '|' + classroom.id + '|' + index]"
                  >
                    <span
                      v-if="
                        calendar[day.code + '|' + classroom.id + '|' + index]
                          .text
                      "
                      class="badge bg-primary"
                      v-html="
                        calendar[day.code + '|' + classroom.id + '|' + index]
                          .text
                      "
                    >
                    </span>
                    <span
                      v-if="
                        !calendar[day.code + '|' + classroom.id + '|' + index]
                          .text
                      "
                    >
                      {{
                        calendar[day.code + "|" + classroom.id + "|" + index]
                          .teacher_name
                      }}
                      <br />
                      <u>
                        {{
                          subjectName(
                            calendar[
                              day.code + "|" + classroom.id + "|" + index
                            ].subject_id
                          )
                        }}</u
                      >
                    </span>
                  </span>
                  <span
                    v-if="
                      !calendar[day.code + '|' + classroom.id + '|' + index]
                    "
                    class="badge text-muted"
                  >
                    <i>فارغة</i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 table-responsive" v-if="type == 'teacher'">
          <div class="col-12 text-center">
            <h2 class="g">
              الجدول المدرسي الخاص بالمعلم <u>{{ teacherName(id) }}</u>
            </h2>
          </div>
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>اليوم</th>
              <th>الأولى</th>
              <th>الثانية</th>
              <th>الثالثة</th>
              <th>الرابعة</th>
              <th>الخامسة</th>
              <th>السادسة</th>
              <th>السابعة</th>
              <th>الثامنة</th>
            </thead>
            <tbody>
              <tr v-for="day in days" :key="day.code">
                <td>
                  {{ day.name }}
                </td>
                <td v-for="index in 8" :key="index">
                  <span v-if="teacherCalendar[day.code]">
                    <span v-if="teacherCalendar[day.code][index]">
                      {{
                        classroomName(
                          teacherCalendar[day.code][index].classroom
                        )
                      }}
                      <br />
                      <u>
                        {{
                          subjectName(
                            teacherCalendar[day.code][index].subject_id
                          )
                        }}
                      </u>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 text-center">
        تم إنشاء الجدول بواسطة
        <a href="https://tahdir.net" target="_blank">نظام التحضير الذكي</a>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BLink, BModal, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BModal,
    BOverlay,
  },
  data() {
    return {
      loading: true,
      calendar: {},
      type: null,
      id: null,
      classroom: {},
      days: [
        {
          code: "1",
          name: "الأحد",
          list: {},
        },
        {
          code: "2",
          name: "الاثنين",
          list: {},
        },
        {
          code: "3",
          name: "الثلاثاء",
          list: {},
        },
        {
          code: "4",
          name: "الاربعاء",
          list: {},
        },
        {
          code: "5",
          name: "الخميس",
          list: {},
        },
      ],
      subjects: [],
      classes: [],
      teacherCalendar: {},
    };
  },
  created() {
    var type = window.location.href.split("/")[4] == "c" ? "class" : "teacher",
      print = window.location.href.includes("#") ? true : false,
      table_id = window.location.href.split("/")[5],
      g = this,
      id = window.location.href.split("/")[6];
    g.type = type;
    g.id = id;
    $.post(api + "/table/public", {
      type: type,
      table_id: table_id,
      id: id,
    })
      .then(function (data) {
        data = JSON.parse(data);
        if (data.status != 100) {
          swal.fire(
            "لم يتم العثور على الجدول المطلوب",
            "برجاء التواصل مع المدرسة",
            "warning"
          );
        } else {
          if (type == "class") {
            g.classroom = {
              id: id,
            };
          }
          g.classes = data.response.classes;
          g.subjects = data.response.subjects;
          g.calendar = data.response.calendar;
          if (type == "teacher") {
            for (const [key, value] of Object.entries(g.calendar)) {
              var day = key.split("|")[0],
                classroom = key.split("|")[1],
                lesson = key.split("|")[2];
              if (value) {
                if (value.teacher_name) {
                  if (value.teacher_id == id) {
                    if (!g.teacherCalendar[day]) {
                      g.teacherCalendar[day] = {};
                    }
                    g.teacherCalendar[day][lesson] = value;
                  }
                }
              }
            }
          }
          if (print) {
            setTimeout(() => {
              window.print();
            }, 1000);
          }
        }
        g.loading = false;
      })
      .catch(function (e) {
        swal.fire(
          "حدث خطأ اثناء الاتصال بالخادم",
          "برجاء المحاولة مرة أخرى",
          "warning"
        );
        g.loading = false;
      });
  },
  methods: {
    subjectName(id) {
      var n = null;
      this.subjects.forEach(function (a) {
        if (a.id == id) {
          n = a.fullname;
        }
      });
      return n;
    },
    classroomName(id) {
      var n = null;
      this.classes.forEach(function (a) {
        a.classrooms.forEach(function (a2) {
          if (a2.id == id) {
            n = a2.title;
          }
        });
      });
      return n;
    },
    teacherName(id) {
      var n = null;
      for (const [key, value] of Object.entries(this.calendar)) {
        if (value) {
          if (value.teacher_name) {
            if (value.teacher_id == id) {
              n = value.teacher_full_name;
              break;
            }
          }
        }
      }
      return n;
    },
  },
};
</script>

<style>
</style>